import React from 'react';
import classNames from 'classnames';
import * as styles from './Spinner.module.scss';

export default React.memo(function Spinner({
  isLoading = true,
  size = 60,
  className,
  isCentered,
  style = {},
  color,
  trackColor,
  duration
}) {
  return (
    isLoading && (
      <div
        className={classNames(
          'spinner',
          styles.spinnerWrapper,
          className,
          {
            [styles.centered]: isCentered
          }
        )}
        style={{ ...style, width: size, height: size }}
      >
        <div
          className={styles.spinner}
          style={{
            borderWidth: Math.ceil(size / 10),
            animationDuration: duration ? `${duration}ms` : null,
            borderColor: trackColor,
            borderLeftColor: color
          }}
        />
      </div>
    )
  );
});
