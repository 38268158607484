import React from 'react';
import Close from '@images/close.svg';
import classNames from 'classnames';
import * as styles from './CloseButton.module.scss';

export default React.memo(function CloseButton({
  className,
  children,
  size = 58,
  style = {},
  ...restProps
}) {
  const buttonSize = children || !size ? null : size;
  const buttonStyle = {
    width: buttonSize,
    height: buttonSize,
    fontSize: buttonSize,
    ...style
  };

  return (
    <button
      className={classNames(styles.close, className, {
        [styles.hasText]: !!children
      })}
      style={buttonStyle}
      {...restProps}
    >
      <Close />
      {children}
    </button>
  );
});
