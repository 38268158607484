import React from 'react';

// Draws a dashed path. Path can either be
// a closed rectangle or just corners
export default React.memo(function Line({
  className,
  strokeWidth = 3,
  color = '#142142',
  side = 'all',
  dashArray,
  dashLong = 50,
  dashShort = 10,
  dashGap = 10,
  path,
  dashOffset
}) {
  const o = Number(strokeWidth) / 2; // Offset for stroke width

  const pathDefaults = {
    all: {
      path: `M${o} ${o} H ${100 - o} V ${100 - o} H ${o} Z`,
      dashOffset: 25
    },
    topRight: {
      path: `M0 ${o} H ${100 - o} V 100`,
      dashOffset: 25
    },
    bottomRight: {
      path: `M${100 - o} 0 V ${100 - o} H 0`,
      dashOffset: 25
    },
    bottomLeft: {
      path: `M${o} 0 V ${100 - o} H 100`,
      dashOffset: 25
    },
    topLeft: {
      path: `M${o} 100 V ${o} H 100`,
      dashOffset: 25
    }
  };

  if (!pathDefaults[side]) {
    console.error('Invalid side');
    return null;
  }

  let _dashArray = dashArray ?? [
    dashLong,
    dashGap,
    dashShort,
    dashGap,
    dashShort,
    dashGap
  ];

  if (Array.isArray(_dashArray)) {
    _dashArray = _dashArray.join(' ');
  }

  const _path = path ?? pathDefaults[side].path;
  const _dashOffset = dashOffset ?? pathDefaults[side].dashOffset;

  return (
    <svg
      className={className}
      width="100"
      height="100"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <path
        d={_path}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={_dashArray}
        strokeDashoffset={_dashOffset}
        vectorEffect="non-scaling-stroke"
        fill="none"
      />
    </svg>
  );
});
