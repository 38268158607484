// extracted by mini-css-extract-plugin
export const wrapper = "Navigation-module--wrapper--56rr2";
export const contentWrapper = "Navigation-module--contentWrapper---_Ohq";
export const logoWrapper = "Navigation-module--logoWrapper--3vNIn";
export const mobileLogo = "Navigation-module--mobileLogo--1Ka56";
export const mobileClose = "Navigation-module--mobileClose--1Bd19";
export const logo = "Navigation-module--logo--2kkMl";
export const content = "Navigation-module--content--3BPni";
export const contact = "Navigation-module--contact--1RQIV";
export const nav = "Navigation-module--nav--2dqRU";
export const current = "Navigation-module--current--2R-VW";
export const corner = "Navigation-module--corner--gLgQd";
export const divider = "Navigation-module--divider--3s_Nr";
export const button = "Navigation-module--button--3brgL";
export const mobileMenuButton = "Navigation-module--mobileMenuButton--1Rks0";
export const mobileMenuBorder = "Navigation-module--mobileMenuBorder--2UAOX";
export const closeButton = "Navigation-module--closeButton--31lo1";
export const mobileContactWrapper = "Navigation-module--mobileContactWrapper--O0qnJ";
export const mobileMenuOpen = "Navigation-module--mobileMenuOpen--xuwg9";
export const navFadeIn = "Navigation-module--navFadeIn--3Q0nu";
export const navItemIn = "Navigation-module--navItemIn--Kz4z4";
export const dividerIn = "Navigation-module--dividerIn--3DW8c";
export const mobileContact = "Navigation-module--mobileContact--3QChf";
export const desktopContact = "Navigation-module--desktopContact--1bUlh";