import { useState, useEffect, useRef } from 'react';

export default function useWindowSize(
  callback,
  shouldReturnValues = true
) {
  const [size, setSize] = useState({
    width: 0,
    height: 0
  });

  const prevSize = useRef({ ...size });

  useEffect(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight
    });

    prevSize.current = { width: 0, height: 0 };
  }, []);

  useEffect(() => {
    const handler = e => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (
        prevSize.current.width !== width ||
        prevSize.current.height !== height
      ) {
        const newSize = { width, height };
        prevSize.current = newSize;

        if (callback) {
          callback(newSize);
        }

        if (shouldReturnValues) {
          setSize(newSize);
        }
      }
    };

    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [shouldReturnValues, callback]);

  return shouldReturnValues ? size : null;
}
