import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import Spinner from '@components/Spinner';
import * as styles from './Button.module.scss';

export function Button({
  className,
  theme,
  isLoading,
  children,
  ...restProps
}) {
  let Tag = 'button';

  if (restProps.to) {
    Tag = Link;
  } else if (restProps.href) {
    Tag = 'a';
  }

  return (
    <Tag
      className={classNames('button', styles.button, className, {
        [styles.outline]: theme === 'outline',
        [styles.loading]: isLoading
      })}
      {...restProps}
    >
      {isLoading && (
        <Spinner
          color={theme !== 'outline' ? '#fff' : null}
          trackColor={theme !== 'outline' ? '#5ea2f5' : null}
          size={20}
          isCentered
        />
      )}
      <span className={styles.text}>{children}</span>
    </Tag>
  );
}

export default Button;

export function ButtonOutline(props) {
  return <Button
    theme="outline"
    {...props}
  />;
}

export function ButtonsWrapper({ className, ...restProps }) {
  return (
    <div
      className={classNames(styles.buttonsWrapper, className)}
      {...restProps}
    />
  );
}
