import React from 'react';
import classNames from 'classnames';
import * as styles from './Divider.module.scss';

export default function Divider({
  className,
  strokeWidth = 2,
  color = '#7D89A7',
  direction = 'horizontal',
  ...restProps
}) {
  const h = Math.ceil(strokeWidth / 2);

  const svgProps = {};
  const lineProps = {};

  if (direction === 'vertical') {
    svgProps.viewBox = `0 0 ${strokeWidth} 100`;
    svgProps.width = strokeWidth;
    svgProps.height = 100;

    lineProps.x1 = h;
    lineProps.x2 = h;
    lineProps.y1 = 0;
    lineProps.y2 = 100;
  } else {
    svgProps.viewBox = `0 0 100 ${strokeWidth}`;
    svgProps.width = 100;
    svgProps.height = strokeWidth;

    lineProps.x1 = 0;
    lineProps.x2 = 100;
    lineProps.y1 = h;
    lineProps.y2 = h;
  }

  return (
    <svg
      className={classNames(styles.divider, className, {
        [styles.vertical]: direction === 'vertical'
      })}
      {...svgProps}
      preserveAspectRatio="none"
      {...restProps}
    >
      <line
        {...lineProps}
        strokeWidth={strokeWidth}
        stroke={color}
        vectorEffect="non-scaling-stroke"
        strokeDasharray="38 8 8 8 8 8"
      />
    </svg>
  );
}
