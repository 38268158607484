import React from 'react';
import classNames from 'classnames';

export default function Section({
  className,
  tag: Tag = 'section',
  theme = 'light',
  noPadding,
  children,
  ...restProps
}) {
  return (
    <Tag
      className={classNames('section', className, {
        dark: theme === 'dark',
        'no-padding': noPadding
      })}
      {...restProps}
    >
      {children}
    </Tag>
  );
}

export function DotSection({ className, ...restProps }) {
  return (
    <Section
      className={classNames('dot-section', className)}
      {...restProps}
    />
  );
}
