import React from 'react';
import classNames from 'classnames';

export default function Content({
  className,
  tag: Tag = 'div',
  children,
  ...restProps
}) {
  return (
    <Tag
      className={classNames('content-wrapper', className)}
      {...restProps}
    >
      {children}
    </Tag>
  );
}
