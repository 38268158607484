// extracted by mini-css-extract-plugin
export const wrapper = "Footer-module--wrapper--25mFf";
export const top = "Footer-module--top--3YYXE";
export const bottom = "Footer-module--bottom--2RX2W";
export const logo = "Footer-module--logo--2x2bG";
export const navigation = "Footer-module--navigation--2BoMD";
export const current = "Footer-module--current--3y2ni";
export const contactWrapper = "Footer-module--contactWrapper--mYNH4";
export const contact = "Footer-module--contact--388ag";
export const divider = "Footer-module--divider--1tzv6";
export const copyright = "Footer-module--copyright--baNeO";