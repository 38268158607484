import React, { useState, useEffect } from 'react';
import _routes from '@routes';
import { Content } from '@layout';
import Logo from '@images/logo.svg';
import Button from '@components/Button';
import Divider from '@components/Divider';
import CloseButton from '@components/CloseButton';
import Corner from '@components/Corner';
import { Link } from 'gatsby';
import useSiteMetadata from '@hooks/useSiteMetadata';
import * as styles from './Navigation.module.scss';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import classNames from 'classnames';

const mobileMenuMaxWidth = 900;
const routes = _routes.filter(r => !r.to.match(/^\/contact/));

export default function Navigation() {
  const { name, email, phone } = useSiteMetadata();
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const keydownHandler = e => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        e.preventDefault();
        setMobileMenuIsOpen(false);
      }
    };

    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= mobileMenuMaxWidth);
    };

    checkIsMobile();
    window.addEventListener('keydown', keydownHandler);
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('keydown', keydownHandler);
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const contactButton = (
    <Button
      className={styles.button}
      to="/contact"
    >
      Contact Us
    </Button>
  );

  return (
    <header
      id="header"
      className={classNames(styles.wrapper, {
        [styles.mobileMenuOpen]: isMobile && mobileMenuIsOpen
      })}
    >
      <Content className={styles.contentWrapper}>
        <Link
          to="/"
          className={styles.logoWrapper}
          aria-label={name}
        >
          <Logo className={styles.logo} />
        </Link>

        <RemoveScroll
          className={styles.content}
          enabled={isMobile && mobileMenuIsOpen}
        >
          <FocusLock
            autoFocus={false}
            disabled={!isMobile || !mobileMenuIsOpen}
          >
            <address
              className={classNames(styles.contact, styles.desktopContact)}
            >
              <a href={`tel:${phone}`}>{phone}</a>
              <a href={`mailto:${email}`}>{email}</a>
            </address>

            <nav
              id="navigation"
              className={styles.nav}
            >
              <CloseButton
                className={styles.closeButton}
                size={66}
                onClick={() => setMobileMenuIsOpen(false)}
              />
              <ul>
                {routes.map(({ text, to }, index) => {
                  const baseDelay = 20;
                  const iterationDelay = 120;
                  const animationDelay = `${
                    baseDelay + iterationDelay * index
                  }ms`;

                  const dividerBaseDelay = 100;
                  const dividerIterationDelay = 220;
                  const dividerAnimationDelay = `${
                    dividerBaseDelay + dividerIterationDelay * index
                  }ms`;

                  return (
                    <li key={index}>
                      <Link
                        to={to}
                        activeClassName={styles.current}
                        partiallyActive={to !== '/'}
                        style={{ animationDelay }}
                      >
                        {text}
                      </Link>

                      {index < routes.length - 1 && (
                        <Divider
                          className={styles.divider}
                          style={{
                            animationDelay: dividerAnimationDelay
                          }}
                          color="#dadada"
                        />
                      )}
                    </li>
                  );
                })}
                {!isMobile && <li>{contactButton}</li>}
              </ul>
              {isMobile && (
                <div className={styles.mobileContactWrapper}>
                  <address
                    className={classNames(
                      styles.contact,
                      styles.mobileContact
                    )}
                  >
                    <a href={`tel:${phone}`}>{phone}</a>
                    <a href={`mailto:${email}`}>{email}</a>
                  </address>

                  {contactButton}
                </div>
              )}
              <Corner
                side="topLeft"
                className={styles.corner}
                dashLong="30"
                dashShort="5"
                dashGap="5"
                dashOffset="15"
              />
            </nav>
          </FocusLock>
        </RemoveScroll>

        <button
          className={styles.mobileMenuButton}
          aria-label="Toggle mobile menu"
          onClick={e => {
            e.preventDefault();
            setMobileMenuIsOpen(true);
          }}
        >
          <Corner
            side="topRight"
            className={styles.mobileMenuBorder}
            color="#DCDEE3"
            dashLong="30"
            dashShort="5"
            dashGap="5"
          />
          <span />
        </button>
        {/* </div> */}
      </Content>
    </header>
  );
}

// const routes = [
//   { text: 'Home', to: '/' },
//   { text: 'About Us', to: '/about' },
//   { text: 'Services', to: '/services' },
//   { text: 'Portfolio', to: '/portfolio' }
// ];
