import React from 'react';
import Logo from '@images/logo-white.svg';
import { Link } from 'gatsby';
import useSiteMetadata from '@hooks/useSiteMetadata';
import routes from '@routes';
import Divider from '@components/Divider';
import * as styles from './Footer.module.scss';

export default function Footer() {
  const { name, email, phone } = useSiteMetadata();

  return (
    <div
      id="footer"
      className={styles.wrapper}
    >
      <div className={styles.top}>
        <Link
          to="/"
          className={styles.logo}
          aria-label={name}
        >
          <Logo />
        </Link>

        <nav className={styles.navigation}>
          <ul>
            {routes.map(({ text, to }, index) => (
              <li key={index}>
                <Link
                  to={to}
                  activeClassName={styles.current}
                  partiallyActive={to !== '/'}
                >
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <div className={styles.bottom}>
        <div className={styles.contactWrapper}>
          <Divider
            color="#696A74"
            className={styles.divider}
            strokeWidth={3}
          />

          <address className={styles.contact}>
            <a href={`tel:${phone}`}>{phone}</a>
            <a href={`mailto:${email}`}>{email}</a>
          </address>
        </div>

        <div className={styles.copyright}>
          © {new Date().getFullYear()} {name}.<br /> All rights reserved
        </div>
      </div>
    </div>
  );
}
