import React from 'react';
import classNames from 'classnames';

export default function Headline({
  tag: Tag = 'h1',
  pretitle,
  pretitleTag,
  pretitleClassName,
  ...restProps
}) {
  return (
    <React.Fragment>
      <Pretitle
        tag={pretitleTag}
        className={pretitleClassName}
      >
        {pretitle}
      </Pretitle>
      <Tag {...restProps} />
    </React.Fragment>
  );
}

export function Pretitle({
  tag: Tag = 'span',
  className,
  children,
  ...restProps
}) {
  return !children ? null : (
    <Tag
      className={classNames('pretitle', className)}
      {...restProps}
    >
      {children}
    </Tag>
  );
}

export const H1 = props => <Headline
  tag="h1"
  {...props}
/>;

export const H2 = props => <Headline
  tag="h2"
  {...props}
/>;

export const H3 = props => <Headline
  tag="h3"
  {...props}
/>;

export const H4 = props => <Headline
  tag="h4"
  {...props}
/>;
