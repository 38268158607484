import React from 'react';
import Navigation from '@components/Navigation';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import './Layout.scss';

export default function Layout({
  title = 'GRS Landscape Architects',
  description,
  children
}) {
  if (!title?.match(/GRS Landscape Architecture/i)) {
    title = `${title.trim()} | GRS Landscape Architecture`;
  }

  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover"
        />
        {description && <meta
          name="description"
          content={description}
        />}
        <meta
          name="theme-color"
          content="#2a87f5"
        />
        <meta
          property="og:type"
          content="website"
        />
      </Helmet>
      <Navigation />
      <main id="main">{children}</main>
      <Footer />
    </React.Fragment>
  );
}
